import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VBtn,
        {
          attrs: {
            color: "primary",
            rounded: "",
            absolute: "",
            fab: "",
            top: "",
            right: "",
          },
          on: {
            click: function ($event) {
              return _vm.open_bank_dialog(null)
            },
          },
        },
        [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
        1
      ),
      this.user_bank_accounts.length == 0
        ? _c("h1", { staticClass: "text-center mt-6" }, [
            _vm._v(" " + _vm._s(_vm.$t("no_bank_accs")) + " "),
          ])
        : _vm._e(),
      _c(
        VList,
        { staticClass: "my-4" },
        [
          _c(
            VRow,
            { attrs: { justify: "center" } },
            _vm._l(this.user_bank_accounts, function (acc, i) {
              return _c(
                VCol,
                {
                  key: i,
                  staticClass: "text-center",
                  attrs: { cols: "12", md: "3" },
                },
                [
                  _c("h4", [_vm._v(_vm._s(acc.Bank))]),
                  !acc.IsForeign
                    ? _c("p", { staticClass: "agency-acc" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("agency")) +
                            " " +
                            _vm._s(acc.Agency) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "agency-acc" }, [
                    _vm._v(
                      _vm._s(_vm.$t("account")) + " " + _vm._s(acc.Account)
                    ),
                  ]),
                  acc.IsForeign
                    ? _c("p", { staticClass: "agency-acc" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("beneficiary")) +
                            " " +
                            _vm._s(acc.Beneficiary) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    VBtn,
                    {
                      staticClass: "my-1 mx-auto",
                      attrs: { color: "primary", height: "2px" },
                      on: {
                        click: function ($event) {
                          return _vm.open_bank_dialog(acc)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("edit")) + " ")]
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm.dialog
        ? _c(
            VDialog,
            {
              attrs: { width: "40%" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c("AddBankAccountModal", {
                attrs: { BankAcc: _vm.bank_acc },
                on: {
                  close: function ($event) {
                    _vm.dialog = false
                  },
                  add: _vm.add_account,
                  edit: _vm.edit_account,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }